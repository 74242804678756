import { Show } from "solid-js";
import { gotoResults, setView } from "..";
import { getCurrGame, tricksMismatch } from "../gameUtil";
import { players } from "../pages/MainPage";
import { BTIcon } from "./bootstrap/BTIcon";

export function ResultsButton() {
    const hasMismatch = () => tricksMismatch.some(t => t !== 0);

    const actualButton = <>
        <button
            class="btn btn-success ms-2"
            onclick={() => {
                setView("results");
                gotoResults();
            }}
            disabled={hasMismatch()}
        >
            <BTIcon icon="trophy-fill" />
            Ergebnise anzeigen
        </button>
    </>;

    return <>
        <Show when={getCurrGame(players) === 0 && players.length}>
            {/* <Show when={hasMismatch()} fallback={actualButton}> */}
            <span>{actualButton}</span>
            {/* </Show> */}
        </Show>
    </>;
}
