import { Match, Switch, createSignal, lazy } from "solid-js";
import { render } from "solid-js/web";
import { CONFIG } from "../config";
import { Player } from "../types";
import { PlayerDataProvider } from "./components/PlayerDataContext";
import { App } from "./pages/MainPage";

const Results = lazy(() => import("./pages/ResultsPage"));

// @ts-ignore: defined with esbuild
LIVE_RELOAD && import("./liveReload");

export const LOCAL_STORAGE_KEY = "gameData";
export const [view, setView] = createSignal<"main" | "results">("main");
export const [syncState, setSyncState] = createSignal<"loading" | "open" | "unavailable" | "unauthorized">("loading");

saveToken();

const initalPlayerData = localStorage.getItem(LOCAL_STORAGE_KEY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)!) : [];
render(() =>
    <PlayerDataProvider initialPlayers={initalPlayerData}>
        <Switch>
            <Match when={view() === "main"}>
                <App />
            </Match>
            <Match when={view() === "results"}>
                <Results onExit={() => setView("main")} />
            </Match>
        </Switch>
    </PlayerDataProvider>
    , document.getElementById("app") as Element);

export async function sendChanges(playerData: Player[]) {
    if (syncState() === "unauthorized")
        return;

    const token = localStorage.getItem("token");

    const body = {
        data: playerData,
    };

    let result: Response;

    try {
        result = await fetch(`${CONFIG.apiDomain}/gamedata`, {
            method: "PATCH",
            body: JSON.stringify(body),
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            }
        });
    } catch (error) {
        setSyncState("unavailable");
        return;
    }

    if (result.status === 200)
        setSyncState("open");
    if (result.status === 401)
        setSyncState("unauthorized");
    else if (!result.ok)
        setSyncState("unavailable");
    return;
}

export async function gotoResults() {
    const token = localStorage.getItem("token");

    await fetch(`${CONFIG.apiDomain}/show_results`, {
        method: "POST",
        mode: "cors",
        headers: {
            Authorization: token
        }
    });
}

function saveToken() {
    const token = new URLSearchParams(location.search).get("token");

    if (token) {
        localStorage.setItem("token", token);
        location.href = location.pathname;
    }
}